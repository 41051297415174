import React from "react"
import { RiWhatsappFill } from "react-icons/ri"
import { IconContext } from "react-icons";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Footer = () => {
  return (
    <div className="container py-12 md:flex  md:items-center md:justify-between text-center">
            <div className="mt-8 md:mt-0 md:order-1 w-full">

        <div className="mt-5">
        <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 leading-tight tracking-tight text-center">
        רוצים לדבר? פשוט חייגו:  <span className="text-gray-900 hover:underline">
          <a href="tel:0542158851"
          onClick={() => {trackCustomEvent({category: "footer_phonecall", action: "Click"})}}
          >054-2158851</a></span>
        </h2>
        <br/>
        <h2 className="text-2xl sm:text-3xl font-semibold text-gray-900 leading-tight tracking-tight text-center">
        או שלחו הודעה ב-WhatsApp:
        </h2>
        <IconContext.Provider value={{ color: '#4FCE5D', size: '100px' }}>
          <div className="m-5 flex justify-center">
            <a href="https://api.whatsapp.com/send?phone=972542158851&text=%D7%94%D7%99%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {trackCustomEvent({category: "footer_whatsapp", action: "Click"})}}
            >
              <RiWhatsappFill />
            </a>
          </div>
        </IconContext.Provider>
      </div>
        <p className="text-center text-sm md:text-base text-gray-700">
        2020 yaronbaruh &copy; כל הזכויות שמורות.
        </p>
      </div>
    </div>
  )
}

export default Footer
