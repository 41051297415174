import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars, FaPhoneAlt } from "react-icons/fa"
import { RiWhatsappFill, RiFacebookCircleFill } from "react-icons/ri"
import { IconContext } from "react-icons";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="w-full ">
      <div className="container w-full md:flex md:items-center md:justify-between p-2">
        <div className="flex space-x-4">
        <a
        href="https://www.facebook.com/Yaron-Baruh-%D7%90%D7%93%D7%A8%D7%99%D7%9B%D7%9C%D7%95%D7%AA-%D7%A2%D7%99%D7%A6%D7%95%D7%91-%D7%A4%D7%A0%D7%99%D7%9D-1432461563728653/"
        target="_blank"
        rel="noreferrer noopener"
        className="text-white hover:text-blue-600 transition duration-150 ease-in-out ml-2"
        onClick={() => {trackCustomEvent({category: "header_facebook", action: "Click"})}}
        >
          <IconContext.Provider value={{ size: '22px' }}>
            <RiFacebookCircleFill className="text-gray-900" />
          </IconContext.Provider>
        </a>

        <a href="https://api.whatsapp.com/send?phone=972542158851&text=%D7%94%D7%99%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {trackCustomEvent({category: "header_whatsapp", action: "Click"})}}
            >
              <IconContext.Provider value={{ size: '22px' }}>
                <RiWhatsappFill />
              </IconContext.Provider>
            </a>

          <span className="text-gray-900">טלפון</span>
          &nbsp;
          <span className="text-gray-900 hover:underline">
            <a href="tel:0542158851"
            onClick={() => {trackCustomEvent({category: "header_phonecall", action: "Click"})}}
            >054-2158851</a>
          </span>
          <FaPhoneAlt className="text-gray-900 mt-1" />
          </div>

      </div>
      </div>
      <div className="container pt-4 pb-8 md:pt-8">
        <div>
        </div>
        <div className="flex justify-between items-center flex-row-reverse ">
          <div className="flex justify-between items-center flex-col">
          <Link to="/">
            <h2 className="font-mono font-semibold text-3xl sm:text-4xl text-gray-900 leading-tight tracking-tighter">
            yaron baruh
            </h2>
          </Link>
            <span className="font-mono text-lg sm:text-xl text-gray-900 leading-tight tracking-tight">אדריכלות ועיצוב פנים</span>
          </div>

          <button
            className="sm:hidden"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
          </button>

          <div className="hidden sm:block">
            {site.data.menu.map((link, key) => (
              <Link
                key={`menu_desktop_link${key}`}
                className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out"
                activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
                to={link.to}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>

        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
    </div>
  )
}

export default Header
